export const LIVE_SITE_URL = 'https://www.vouchedfor.co.uk/';
export const ABOUT_URL = 'https://about.vouchedfor.co.uk/';
export const ASSET_URL = 'https://assets.vouchedfor.co.uk/img/';
export const DEPT_ADVICE_SERVICE = 'https://www.moneyadviceservice.org.uk/en/articles/what-is-a-debt-management-plan';
export const DEPT_SAVING_EXPERT = 'https://www.moneysavingexpert.com/loans/debt-help-plan/';
export const S3_URL = 'https://s3.eu-west-1.amazonaws.com/professionals.vouchedfor.co.uk/';
export const GUIDE_URL = 'https://guide.vouchedfor.co.uk/2020FinancialAdvisers/TheTimes/HowToQualify/?utm_source=homepage';
export const ACCOUNTANTS_GUIDE_URL = 'https://guide.vouchedfor.co.uk/2019-accountants';
export const PENSIONS_ADVISORY_SERVICE = 'https://www.pensionsadvisoryservice.org.uk';
export const TRUST_PILOT_URL = 'https://uk.trustpilot.com/review/www.vouchedfor.co.uk';

export const SOCIAL_MEDIA_URLS = {
    TWITTER: 'https://twitter.com/VouchedFor/',
    FACEBOOK: 'https://www.facebook.com/VouchedFor/',
    LINKEDIN: 'https://www.linkedin.com/company/vouchedfor/',
};

export const COMPANY_URLS = {
    EMAIL_SUPPORT: 'mailto:consumer_support@vouchedfor.co.uk',
    VF_CONTACT_PAGE_URL: `${ABOUT_URL}about/contact`
};

export const POPULAR_FIRM_URLS = [
    '1723-fidelius-ltd',
    '979-the-private-office',
    '1455-octopus-money',
    '546-true-potential-wealth-management-llp',
    '882-platinum-financial-planning-ltd',
    '7987-bvs-mortgages-and-financial-services-ltd',
    '601-westminster-wealth-management-llp',
    '2747-ascot-lloyd',
    '575-hoyl-independent-advisers-ltd',
    '4540-fortura-financial-partners-ltd',
    '12058-mkc-wealth-ltd',
    '4449-clearwater-wealth-management',
    '1568-afh-wealth-management',
    '4474-attivo-financial-ltd',
    '245-one-financial-solutions',
    '12808-argentis-wealth-management',
    '2261-forvis-mazars',
    '1250-ellis-bates-wealth-management-limited',
    '1013-eight-wealth-management-principal-partner-of-st-james-s-place',
    '18-succession-wealth',
    '1684-quilter-financial-advisers-ltd',
    '9118-retireinvest-limited',
    '2155-sandringham-financial-partners',
    '12723-lifesearch',
    '14989-lawrence-clarke-ltd',
    '42-2plan-wealth-management-limited',
    '1000-amber-river-true-bearing',
    '4491-cooper-associates-wealth-management-ltd',
    '7820-mattioli-woods',
    '5481-chartwell-financial-services',
    '4870-pinnacle-wealth-management-llp',
    '12182-intelligent-pensions-ltd',
    '501-beaumont-wealth',
    '12199-cullen-wealth-limited',
    '1880-azets-wealth-management',
    '8529-newcastle-financial-advisers-limited',
    '1636-foster-denovo-limited',
    '39-wealth-of-advice',
    '9267-hoxton-capital-management-uk-ltd',
    '856-flying-colours-advice-limited',
    '7498-gallimore-wealth-management-llp'
]
